let CACHED_MAP: Map<string, string>;

export function getIconMap(): Map<string, string> {
  if (!CACHED_MAP) {
    const win = window as any;
    win.Laricons = win.Laricons || {};
    CACHED_MAP = win.Laricons.map = win.Laricons.map || new Map();
  }
  return CACHED_MAP;
}

export function addIcons(icons: {[name: string]: string }) {
  const map = getIconMap();
  Object.keys(icons).forEach(name => {
    map.set(name, icons[name]);
  });
}
