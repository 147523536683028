import { config, configFromSession, configFromURL, saveConfig } from './config';

export default function() {
  const win = typeof (window as any) !== 'undefined' ? window : {} as Window;
  const Larva = ((win as any).Larva = (win as any).Larva || {});

    // create the Larva.config from raw config object (if it exists)
    // and convert Larva.config into a ConfigApi that has a get() fn
  const configObj = {
      ...configFromSession(win),
      persistConfig: false,
      ...Larva.config,
      ...configFromURL(win)
    };

  config.reset(configObj);
  if (config.getBoolean('persistConfig')) {
      saveConfig(win, configObj);
    }

  Larva.config = config;
};