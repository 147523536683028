export interface LarvaConfig {
  /**
   * When it's set to `false`, disables all animation and transition across the app.
   * Can be useful to make ionic smoother in slow devices, when animations can't run smoothly.
   */
  animated?: boolean;

  /**
   * Defaults to `true`.
   */
  swipeBack?: boolean;

  /**
   */
  formatDateTime?: string;

  /**
   */
  formatLongDate?: string;

  /**
   */
  formatShortDate?: string;

  /**
   */
  formatLongTime?: string;

  /**
   */
  formatShortTime?: string;

  /**
   */
  persistConfig?: boolean;
}

export const setupConfig = (config: LarvaConfig) => {
  const win = window as any;
  const Larva = win.Larva;
  if (Larva && Larva.config && Larva.config.constructor.name !== 'Object') {
    console.error('Larva config was already initialized');
    return;
  }
  win.Larva = win.Larva || {};
  win.Larva.config = {
    ...win.Larva.config,
    ...config
  };
  return win.Larva.config;
};
