
let FRAMEWORK_CACHED_MAP: Map<string, string>;


export function getAssetsCacheMap(): Map<string, string> {
  if (!FRAMEWORK_CACHED_MAP) {
    const win = window as any;
    win.LarCacheMap = win.LarCacheMap || {};
    FRAMEWORK_CACHED_MAP = win.LarCacheMap.map = win.LarCacheMap.map || new Map();
  }
  return FRAMEWORK_CACHED_MAP;
}

export function addCachedAssets(assets: {[name: string]: string }) {
  const map = getAssetsCacheMap();
  Object.keys(assets).forEach(name => {
    map.set(name, assets[name]);
  });
}
