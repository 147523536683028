import i18next from 'i18next';
export * from 'i18next';
const i18n = i18next;
export { i18n };

i18n
  .init({
    ns: ['larva'],
    debug: false,
    defaultNS: 'larva',
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    load: 'languageOnly',
    saveMissing: false,
    resources: {
      en: {
        larva: {
          date: {
            months: {
              1: 'January',
              2: 'February',
              3: 'March',
              4: 'April',
              5: 'May',
              6: 'June',
              7: 'July',
              8: 'August',
              9: 'September',
              10: 'October',
              11: 'November',
              12: 'December',
            },
          },
          node: {
            more: 'More',
            linkednodes: 'Linked nodes'
          },
          cam: {
            tap_to_exit_fullscreen: 'Tap to exit full screen mode'
          },
          log: {
            log: 'Log',
            userRemoved: 'User Removed, user id: {id}',
            resolved: 'Resolved',
            armed: 'Armed',
            nologs: 'No Logs available.'
          },
          log_entries: {
            // @SEE @larva.io/report-codes
          },
          error_messages: {
            Timeout: 'Request Timeout.',
            TIMEOUT: 'Request Timeout.',
            UNKNOWN_ERROR: 'Unknown error.',
            // area actions
            SECURITY_SYSTEM_NOT_INTIALIZED: 'Security sytem not initalized.',
            AREA_ARM_FAILED_INVALID_ZONE_STATE: 'All area zones needs to be closed.',
            AREA_ARM_FAILED_TROUBLES_EXIST: 'There is uncleared trouble.',
            AREA_ARM_FAILED_FAULTS_EXIST: 'There is uncleared trouble.',
            AREA_ARM_FAILED_ALREADY_ARMED: 'Area allready armed.',
            AREA_ARM_FAILED_ALREADY_UNARMED: 'Area allready unarmed.',
            AREA_TROUBLE_ALREADY_ACKED: 'Trouble allready cleared.',
            AREA_TROUBLE_NOT_RESOLVED: 'Trouble not resolved.',
            AREA_TROUBLE_ALREADY_RESOLVED: 'Trouble allready resolved.',
            INVALID_TROUBLE_ID: 'Invalid trouble.',
            INVALID_AREA_ID: 'Invalid area.',
            // other
            'jwt expired': 'Token Expired. Please login again.',
            'Unknown SubComponent': 'Unknown SubComponent.',
          },
          slidingDoor: {
            open: 'Open',
            close: 'Close',
            stop: 'Stop',
            stateOpen: 'Open',
            stateClosed: 'Closed',
            stateOpening: 'Opening',
            stateClosing: 'Closing',
            stateUnknown: 'Unknown'
          },
          twoPosController: {
            on: 'On',
            off: 'Off',
            auto: 'Auto'
          },
          utilityMeter: {
            currentMonthReading: 'Current month\'s meter reading:',
            currentMonthUsage: 'Current month\'s usage:'
          },
          onoff: {
            on: 'On',
            off: 'Off',
            switchoff: 'Switch Off',
            switchon: 'Switch On',
          },
          door: {
            buzzOpen: 'Buzz Open',
            toggleOpen: 'Toggle Open',
            toggleLockUnlock: 'Toggle lock/unlock',
            lock: 'Lock',
            unlock: 'Unlock',
            locked: 'Locked',
            unlocked: 'Unlocked',
            error: 'System error',
          },
          elevators: {
            on_way: 'Elevator is on it\'s way',
            call_canceled: 'Your call was cancelled',
            call_canceled_short: 'Cancelled',
            call_timeout: 'Call failed with timeout',
            call_timeout_short: 'Timeout',
            callto: 'Call elevator to:',
            arrived: 'Elevator arrived',
            arrived_short: 'Arrived',
          },
          area: {
            arm: 'Arm',
            disarm: 'Disarm',
            armed: 'Armed',
            disarmed: 'Disarmed',
            partalArmedWithTrouble: 'Part-armed',
            armedWithTouble: 'Armed',
            partalArmed: 'Partial Armed',
            selectZonesToBypass: 'Select Zones you want to bypass.',
            partialarm: 'Partial Arm',
            overrideAndBypass: 'Override and Bypass',
            overridePartialArm: 'Override and Partial Arm',
            overridearm: 'Override and Arm',
            zonemonitor: 'Zone Monitor',
            troubles: 'Troubles & Alarms',
            entrydelay: 'Entry Delay...{seconds}s',
            exitdelay: 'Exit Delay...{seconds}s',
            faultedOrTampered: 'Fault or Tamper',
            clear: 'Clear',
            clearSelection: 'Clear Selection',
            overrideTroblesZonesInfo: 'You\'re going to override {troubles} trouble(s) and bypass {zones} zone(s).',
            overrideZonesInfo: 'You\'re going to bypass {zones} zone(s).',
            zoneTroubles: 'Zone troubles',
            systemTroubles: 'System troubles',
            areaNotReady: 'Area not ready. Please close all zones before arming.',
            alarmsTroubles: 'Alarms',
            alarm: 'Alarm',
            alarms: {
              medical: 'Medical Alarm!',
              intruder: 'Intruder Alarm!',
              fire: 'Fire Alarm!',
              other: 'Other Alarm!',
              panic: 'Panic Alarm!',
              multiple: 'Multiple Alarms!'
            },
            trouble_code_messages: {
              0: 'Intruder alarm',
              1: 'Zone tampered',
              2: 'Zone fault',
              3: 'Hold-up Alarm',
              4: 'Interconnection fault',
              5: 'Prime power source fault',
              6: 'Battery fault',
              7: 'Alarm transmission system fault',
              8: 'Bell fault',
              9: 'Alarm transmission system fault',
            },
            zone_state: {
              open: 'Open',
              closed: 'Closed',
              short_circuit: 'Trouble',
              bypass: 'Bypass',
              bypassed: 'Bypassed',
              open_circuit: 'Tamper',
            }
          },
          intercom: {
            calling: 'Calling...',
          },
          weather: {
            temperature: 'Temperature',
            temperatureFeelsLike: 'Temperature feels like',
            drewPoint: 'Drew point',
            solarIrradiation: 'Solar Irradiation',
            windSpeed: 'Wind speed',
            windDirection: 'Wind direction',
            daylight: 'Daylight',
            humidity: 'Humidity',
            rain: 'Rain',
            snow: 'Snow',
            storm: 'Storm Warning',
            fog: 'Fog',
            true: 'True',
            false: 'False',
          }
        }
      },
    },
  })
